import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import ComponentSelector from "../components/ComponentSelector"

export default function Project({ data, pageContext }) {
  // = = = = = = = = = = = = =
  // Data
  const { title } = pageContext

  const { projectContent, metadata } = data.project.nodes[0]

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;

  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={data?.page?.nodes[0].metadata?.description}
          image={data?.page?.nodes[0].metadata?.image}
        />

        <div className="project">
          <ComponentSelector components={projectContent} />
        </div>

        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    project: allDatoCmsProject(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        introText
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
        featuredImage {
          gatsbyImageData
          alt
          title
        }
        projectContent {
          ... on DatoCmsBasicSlider {
            componentId
            relatedProjects {
              title
            }
            relatedFocusAreas {
              title
            }
            relatedResources {
              title
            }
            id
            headline
            cardColor
            cardAlignment
            showIntroText
            introduction
            contentType {
              contentType
            }
            limit
            link {
              isThisAButton
              label
              linkUrl
            }
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData
              }
            }
            tagToShow
          }
          ... on DatoCmsHeroHeader {
            componentId
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData
              }
            }
            hasNav
            hasBreadcrumb
            breadcrumb {
              label
              linkUrl
            }
            headline
            heroSize
            innerNav {
              links {
                linkUrl
                label
                isThisAButton
              }
            }
            intro
          }
          ... on DatoCmsNarrativeTextBlock {
            componentId
            textContent
            hasImage
            blockWidth
            highlightColor
            imageAlignment
            blockImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED)
                title
              }
            }
          }
          ... on DatoCmsFormCtaBanner {
            componentId
            headline
            description
            donationForm
            formHeadline
            formLink
            amountButtons {
              amount
            }
            recurringDonationOptions {
              recurringDonation
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            rightBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            leftBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsStatisticsRow {
            componentId
            id
            statistics {
              number
              description
            }
          }
          ... on DatoCmsBasicCtaBanner {
            componentId
            headline
            description
            cta {
              label
              linkUrl
            }
            imageAlignment
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            bannerImage {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
